<template>
  <v-card
    class="elevation-5 mx-auto mt-15 rounded-lg"
    :loading="loading"
    width="450"
  >
    <v-card-title class="title primary--text"> Profile </v-card-title>
    <v-card-text class="pa-10">
      <v-form class="text-center" v-if="user.role == 'admin'">
        <div class="container">
          <div
            class="imagePreviewWrapper rounded-pill"
            :style="{
              'background-image': `url(${
                previewImage ? previewImage : 'icons/default_profile.png'
              })`,
            }"
          ></div>
          <div class="edit-icon">
            <v-icon small dark @click="selectImage"> mdi-pencil </v-icon>
          </div>
        </div>

        <input ref="fileInput" type="file" @input="pickFile($event)" hidden />
        <input
          type="file"
          name="profile_pic"
          @change="changeFile($event)"
          ref="profile_pic"
          hidden
        />
        <v-text-field
          label="Name"
          name="name"
          prepend-icon="mdi-account"
          type="text"
          outlined
          dense
          v-model="form.name"
          :error="errors.name != null"
          :error-messages="errors.name"
        ></v-text-field>
        <v-text-field
          label="Email"
          name="email"
          class="emailfield"
          prepend-icon="mdi-email"
          type="email"
          :disabled="!verifyEmail"
          outlined
          dense
          v-model="form.email"
          :error="errors.email != null"
          :error-messages="errors.email"
        >
          <template v-slot:append-outer class="mt-0">
            <v-btn
              height="40px"
              v-if="!verifyEmail"
              @click="sendOTP(form.email)"
              >Edit</v-btn
            >
          </template>
        </v-text-field>
        <v-text-field
          v-if="verifyEmail"
          label="OTP"
          name="otp"
          prepend-icon="mdi-email"
          type="email"
          outlined
          dense
          v-model="optVerify"
          :maxlength="6"
        ></v-text-field>
      </v-form>
      <v-form class="text-center" v-else>
        <div class="container">
          <div
            class="imagePreviewWrapper rounded-pill"
            :style="{
              'background-image': `url(${
                previewImage ? previewImage : 'icons/default_profile.png'
              })`,
            }"
          ></div>
          <div class="edit-icon">
            <v-icon small dark @click="selectImage"> mdi-pencil </v-icon>
          </div>
        </div>
        <input ref="fileInput" type="file" @input="pickFile($event)" hidden />
        <input
          type="file"
          name="profile_pic"
          @change="changeFile($event)"
          ref="profile_pic"
          hidden
        />
        <v-text-field
          label="Name"
          name="name"
          prepend-icon="mdi-account"
          type="text"
          outlined
          dense
          v-model="form.name"
          :error="errors.name != null"
          :error-messages="errors.name"
        ></v-text-field>
        <v-text-field
          label="Email"
          name="email"
          class="emailfield"
          prepend-icon="mdi-email"
          type="email"
          :disabled="!verifyEmail"
          outlined
          dense
          v-model="form.email"
          :error="errors.email != null"
          :error-messages="errors.email"
        >
        </v-text-field>
      </v-form>
      <v-btn
        color="info"
        class="ml-3 px-10 mt-5"
        @click="onSubmit"
        v-bind:disabled="loading"
        >Save</v-btn
      >
    </v-card-text>
    <v-snackbar
      v-model="success"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="success = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="error"
      :timeout="3000"
      color="red accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        profile_url: [],
      },
      errors: {},
      message: "",
      snackbar: false,
      token: null,
      user: {
        name: "",
        email: "",
        profile: null,
      },
      success: false,
      error: false,
      profile_pic: {},
      previewImage: null,
      optVerify: null,
      verifyEmail: false,
      oldEmail: "",
      verifySuccess: false,
      isOTPGenerated: false,
    };
  },
  methods: {
    sendOTP(oldEmail) {
      this.verifyEmail = true;
      this.oldEmail = oldEmail;

      this.$axios
        .post("/generate_email_otp/" + this.user.id, {
          oldEmail: this.oldEmail,
        })
        .then((res) => {
          let data = res.data;
          if (data.success) {
            this.isOTPGenerated = true;
            this.verifySuccess = false;
            this.success = true;
            this.message = data.message;
          } else {
            this.error = true;
            this.message = data.message;
          }
        });
    },
    onSubmit(event) {
      let _self = this;
      event.preventDefault();

      _self.loading = true;
      var formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("profile[]", this.form.profile_url);
      formData.append("email", this.form.email);
      formData.append("otp", this.optVerify);
      this.$axios
        .post("/updateProfile/" + this.user.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          let responsedata = res.data;
          _self.loading = false;

          if (!responsedata.success) {
            _self.errors = {};
            if (responsedata.errors) {
              for (let i = 0; i < responsedata.errors.length; i++) {
                _self.errors[responsedata.errors[i].field] =
                  responsedata.errors[i].message;
              }
            } else {
              _self.error = true;
              _self.message = responsedata.message;
            }
          } else {
            localStorage.setItem("user", JSON.stringify(responsedata.user));
            this.user = responsedata.user;
            this.form.name = responsedata.user.name;
            this.form.email = responsedata.user.email;
            this.form.profile_url = [];
            this.$emit("updateProfilePic");
            _self.success = true;
            _self.message = responsedata.message;
            this.verifyEmail = false;
          }
        });
    },
    openFileExplorer() {
      this.$refs.profile_pic.click();
    },
    changeFile(e) {
      this.profile_pic = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.user.profile_url = e.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      this.$emit("input", e.target.files[0]);
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.form.profile_url = file[0];
      }
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.previewImage = this.user.profile_url;
    this.token = this.user.token;
    this.form.name = this.user.name;
    this.form.email = this.user.email;
  },
};
</script>

<style>
.container {
  position: relative;
}

.edit-icon {
  position: absolute;
  left: 205px;
  top: 70px;
  background-color: black;
  border-radius: 50%;
  padding: 2px;
  height: 25px;
  width: 25px;
  border: 3px solid white;
  padding: 0px !important;
}

.imagePreviewWrapper {
  width: 80px;
  height: 80px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.title {
  background-color: rgba(25, 118, 210, 0.1) !important;
  justify-content: center;
}

.emailfield.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-outer {
  margin-top: 0px !important;
}
</style>
